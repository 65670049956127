import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19dbae76 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _ea9e10c6 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0b3f6e9a = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _591e917d = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _35dde542 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _25ff118e = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _303667c6 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _7ce0dfe1 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _500436da = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _080a0f00 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _44e1179a = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _6f913a36 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _7afa7822 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _bd956e00 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _8510fab4 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _29936f81 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _47b3166a = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _6660ebc1 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _0001568b = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _5686335c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _19dbae76,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _ea9e10c6,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _0b3f6e9a,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _591e917d,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _35dde542,
    name: "receipt"
  }, {
    path: "/search",
    component: _25ff118e,
    name: "search"
  }, {
    path: "/search-booking",
    component: _303667c6,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _7ce0dfe1,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _500436da,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _080a0f00,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _44e1179a,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _6f913a36,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _7afa7822,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _bd956e00,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _8510fab4,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _29936f81,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _47b3166a,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _6660ebc1,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _0001568b,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _5686335c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
